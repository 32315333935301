<template>
	<router-link
		to="/personal/loyalty"
		:class="$s.bg"
	>
		<div :class="[$s.wrapper, 'loyalty_card_wrapper']">
			<div :class="$s.title">Ваши баллы</div>
			<div :class="$s.points">{{ parseFloat(user.BALLS).toLocaleString() }}</div>
			<img
				:class="[$s.img, 'loyalty_card_img']"
				src="~@/assets/img/logo.svg"
			/>
			<div
				:class="$s.title2"
				v-if="user.DATE_EXPIRED"
			>Действуют до:
			</div>
			<div
				:class="$s.exp"
				v-if="user.DATE_EXPIRED"
			>{{ user.DATE_EXPIRED }}
			</div>
		</div>
	</router-link>
</template>

<script>
import {mapState} from 'vuex'

export default {
	computed: mapState(['user'])
}
</script>
<style lang="scss">
.loyalty_card
{
	height: 100%;
	border-radius: 24px !important;

	.loyalty_card_wrapper
	{
		height: 100%;
		grid-template-rows: 25px 95px auto !important;
		padding: 30px 24px 18px 24px !important;
		background: url('~@/assets/img/Personal/points_card_bg.svg') no-repeat center !important;
		@media (max-width: 385px)
		{
			grid-template-rows: 25px 55px auto !important;
			padding: 20px !important;
			background: url('~@/assets/img/Personal/points_card_bg_small.svg') no-repeat center !important;
		}
	}

	.loyalty_card_img
	{
		width: 46px !important;
		height: 46px !important;
		place-self: start end !important;
		@media (max-width: 385px)
		{
			width: 36px !important;
			height: 36px !important;
		}
	}
}
</style>
<style
	module="$s"
	lang="scss"
>
@mixin grid($nn)
{
	.#{$nn}
	{
		grid-area: $nn;
		@content;
	}
}

.bg
{
	box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.08), 0px 2px 14px rgba(0, 0, 0, 0.08);
	background: linear-gradient(177.23deg, #40AE49 -13.49%, #77D67F 109.75%);
	border-radius: 10px;
	display: block;

	.wrapper
	{
		background: url('~@/assets/img/Personal/Menu/points_bg.svg') no-repeat center;
		border-radius: 10px;
		display: grid;
		grid:
                "title img"
                "points img"
                "title2 exp";
		padding: 12px 32px;


		font-family: $mainFontMedium;
		color: #FFFFFF;
		font-size: 14px;
		line-height: 20px;
		font-weight: 500;
		justify-content: space-between;
		@media (max-width: 768px)
		{
			padding: 12px 24px;
		}

		@include grid(title)
		{
			opacity: 0.54;
		}
		@include grid(points)
		{
			font-size: 32px;
			line-height: 39px;
		}
		@include grid(img)
		{
			width: 40px;
			height: 40px;
			place-self: center end;
		}
	;
		@include grid(title2)
		{
			opacity: 0.54;
		}
		@include grid(exp);
	}
}
</style>