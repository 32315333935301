<template>
	<div>
		<div :class="$promo.title">Поделиться в соц. сетях</div>
		<div :class="$promo.icons">
			<a
				v-for="el in list"
				:href="el.to"
			>
				<img :src="el.img"/>
			</a>
		</div>
		<div :class="$promo.p">
			Получайте с каждого заказа друга бонусные баллы, оплачивайте бонусами до 50% от стоимости ваших заказов.
			<a
				v-if="!isMobile"
				href="https://www.vkusnoibistro.com/about/bonus/"
			>Подробнее</a>
			<a
				v-else
				href="javascript:void(0);"
				@click="showTerms()"
			>Подробнее</a>
		</div>
		<Popup
			:visible.sync="isBonusModalVisible"
			v-if="isMobile"
		>
			<Terms
				:terms-text-mode="'bonus'"
				@close="isTermsModalVisible = false"
			/>
		</Popup>
	</div>
</template>

<script>
import Terms from '@/components/popup/Terms.vue';
import {mapState} from "vuex";

export default {
	components: {Terms},
	data()
	{
		return {
			t: 'Вкусно и быстро',
			d: 'Быстрая доставка готовой еды за 30 мин., на работу или домой. Блюда от 50 руб, обед от 179 руб. Зайдите на сайт, введите свой адрес и заказывайте!',
			i: 'https://www.vkusnoibistro.com/images/newSocial.jpg',
			isBonusModalVisible: false,
			isMobile: !!process.env.VUE_APP_MOBILE,
		}
	},
	computed: {
		...mapState('personal', ['promoLink']),
		list()
		{

			return [
				{
					to: "https://vk.com/share.php?url=" + this.promoLink + "&title=" + this.t + "&description=" + this.d + "&image=" + this.i + "&noparse=true",
					img: require('@/assets/img/Personal/shareIcons/vk.svg')
				},
				{
					to: "https://connect.ok.ru/dk?st.cmd=WidgetSharePreview&st.shareUrl=" + this.promoLink + "&st.comments=" + this.t,
					img: require('@/assets/img/Personal/shareIcons/ok.svg')
				},
				{
					to: "https://t.me/share/url?url=" + this.promoLink + "&text=" + this.t + "%0D%0A" + this.d,
					img: require('@/assets/img/Personal/shareIcons/telegramm.svg')
				},]
		}
	},
	methods: {
		showTerms()
		{
			this.isBonusModalVisible = true;
		}
	}
}
</script>

<style
	module="$promo"
	lang="scss"
>
.title
{
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 24px;
	font-family: $mainFontMedium;
}

.icons
{
	a
	{
		margin-right: 4px;

		img
		{
			@media (max-width: 385px)
			{
				width: 32px;
				height: 32px;
			}
		}
	}
}

.p
{
	font-family: $mainFontMedium;
	font-style: normal;
	font-weight: 500;
	font-size: 12px;
	line-height: 17px;

	a
	{
		color: $green;
		text-decoration: underline;
	}
}
</style>