<template>
	<div :class="$loyal.loyalty">
		<div :class="$loyal.title">Программа лояльности</div>
		<div :class="$loyal.main">
			<div :class="$loyal.wrapper">
				<div :class="$loyal.card">
					<Points class="loyalty_card"/>
				</div>
				<div :class="$loyal.promo">
					<Promo/>
				</div>
				<div :class="$loyal.info">
					<div :class="$loyal.infoRow">
						<div :class="$loyal.text">
							<div>Ваш промокод</div>
							{{ promoCode }}
						</div>
						<div
							v-if="isMobile"
							:class="$loyal.share"
							@click="sharePromocode()"
						>
							<img src="~@/assets/img/Personal/share.svg"/>
						</div>
					</div>
					<div :class="$loyal.infoRow">
						<div :class="$loyal.text">
							<div>Ваша реферальная ссылка</div>
							{{ promoLink }}
						</div>
						<div
							v-if="!isMobile"
							:class="$loyal.share"
							@click="sharePromocode()"
						>
							<img src="~@/assets/img/Personal/share.svg"/>
						</div>
					</div>
				</div>
			</div>

			<div :class="$loyal.btn">
				<router-link to="/personal/loyalty/friends">
					Список друзей
				</router-link>
			</div>

			<hr>
			<div :class="$loyal.littleTitle">История транзакций баллов</div>
			<History
				:class="$loyal.history"
				:totalPages="transactionHistoryPageCount"
				:transactions="transactionHistory"
				@updateList="loadHistory($event)"
			/>
		</div>
	</div>
</template>

<script>
import Points from '@/components/block/Personal/Points.vue'
import Promo from '@/components/block/Personal/Promo.vue'
import History from '@/components/block/Personal/TransactionHistory.vue'
import {mapState, mapActions} from 'vuex'

export default {
	components: {Promo, Points, History},
	data()
	{
		return {
			showFriends: false,
			isMobile: !!process.env.VUE_APP_MOBILE
		}
	},
	computed: {
		...mapState(['user']),
		...mapState('personal', ['promoCode', 'promoLink', 'transactionHistory', 'transactionHistoryPageCount']),
	},
	methods: {
		...mapActions('personal', ['loadHistory']),
		copy(val)
		{
			navigator.clipboard.writeText(val)
		},

		sharePromocode()
		{
			try
			{
				if (navigator.share)
				{
					navigator.share({
						title: 'Вкусно и Быстро',
						url: this.promoLink
					})
				}
			} catch (e)
			{
				console.error(e)
			}
		}
	},
	mounted()
	{
		this.loadHistory();
	}
}
</script>

<style
	module="$loyal"
	lang="scss"
>
.loyalty
{
	border-radius: 10px;
	padding: 32px 44px;
	background: #FFFFFF;
	box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.08), 0px 2px 14px rgba(0, 0, 0, 0.08);
	@media (max-width: 780px)
	{
		padding: 32px 22px;
	}
	@media (max-width: 420px)
	{
		padding: 16px 10px;
	}
}

.title
{
	font-style: normal;
	font-weight: bold;
	font-size: 24px;
	line-height: 32px;
}

.main
{
	max-width: 678px;
	width: calc(100vw - 80px);
	@media (max-width: 1280px)
	{
		width: 100%
	}

	.btn
	{
		display: grid;
		place-content: start;

		a
		{
			background: $green;
			border-radius: 100px;
			color: white;
			width: 248px;
			height: 48px;
			display: grid;
			place-content: center;
		}

		@media (max-width: 420px)
		{
			place-content: center;
		}
	}

	hr
	{
		border: none;
		background-color: $greyBorder;
		height: 1px;
		margin-top: 20px;
		@media (max-width: 420px)
		{
			margin-top: 44px;
		}
	}
}

.littleTitle
{
	font-style: normal;
	font-weight: bold;
	font-size: 18px;
	line-height: 28px;
	padding-bottom: 15px;
}

.history
{
	overflow: auto;
}

.wrapper
{
	display: grid;
	grid: "card promo"
        "info info"
      / 1fr 1fr;
	gap: 24px 32px;
	padding-top: 20px;
	padding-bottom: 20px;

	@media (max-width: 980px)
	{
		grid: "card ."
          "promo ."
          "info info"
      / 1fr 1fr;
	}
	@media (max-width: 420px)
	{
		gap: 24px 0;
	}

	.promo
	{
		grid-area: promo;
	}

	.card
	{
		grid-area: card;
		height: 185px;
		width: 315px;
		@media (max-width: 385px)
		{
			height: 144px;
			width: 248px;
		}
	}

	.info
	{
		grid-area: info;
		display: grid;
		grid-template-rows: 1fr fit-content;
		grid-gap: 16px;

		.infoRow
		{
			display: grid;
			min-height: 56px;
			height: fit-content;
			padding: 10px 24px;
			grid-template-columns: 1fr 25px;
			border: 2px solid $greyBorder;
			border-radius: 8px;
			background-color: $greyBackground;

			.text
			{
				font-style: normal;
				font-weight: normal;
				font-size: 16px;
				line-height: 24px;
				@media (max-width: 385px)
				{
					font-size: 14px;
				}

				div
				{
					font-weight: 500;
					font-size: 12px;
					line-height: 14px;
					color: $greyLightText;
					width: 100%;
					@media (max-width: 385px)
					{
						font-size: 12px;
					}
				}
			}

			.share
			{
				margin-top: 7px;
				cursor: pointer;
			}
		}
	}
}
</style>
